(function videos() {
    window.tus = require("tus-js-client");
    const auth = `bearer 92f35c13e8663217fe9182c12d1342e0`;

    window.DOMContentLoaded = getVideos();

    /*
       L I S T
    */
    async function getVideos() {
        // const response = await fetch(`https://api.vimeo.com/me/videos?filter_tags=test_grid`, {
        /**
         * Create a folder and use the id from the url
         * /projects/8354889/
         */
        const response = await fetch(
            `https://api.vimeo.com/me/videos?sort=date&direction=desc&fields=player_embed_url,is_playable,pictures,name,description`, //
            {
                method: "GET",
                headers: {
                    Authorization: auth,
                    "Content-Type": "application/json",
                    Accept: "application/vnd.vimeo.*+json;version=3.4",
                },
            }
        );
        if (!response.ok) {
            console.log("getVideos", response);
            //Show predefined grid of videos just in case...
            return null;
        }

        const result = await response.json();

        const { data } = result;
        const grid = document.querySelector("#video-grid");
        for (let i = 0; i < data.length; i++) {
            const {
                player_embed_url,
                is_playable,
                pictures,
                name,
                description,
            } = data[i];
            /**
       * sizes
       * 0: {width: 100, height: 75,…}
        1: {width: 200, height: 150,…}
        2: {width: 295, height: 166,…}
        3: {width: 640, height: 360,…}
        4: {width: 960, height: 540,…}
        5: {width: 1280, height: 720,…}
        6: {width: 1920, height: 1080,…}
       */
            const thumbnail = pictures.sizes[2];
            const { link_with_play_button, height, width } = thumbnail;
            if (is_playable) {
                const videoImage = document.createElement("img");
                videoImage.setAttribute("src", link_with_play_button);
                // videoImage.setAttribute(
                //     "style",
                //     `width: ${width}px; height: ${height}px;`
                // );
                videoImage.setAttribute("alt", name);

                const videoLink = document.createElement("a");
                videoLink.setAttribute("href", player_embed_url);
                videoLink.setAttribute("class", "ratio ratio-1x1");
                videoLink.setAttribute("alt", name);
                videoLink.appendChild(videoImage);

                const nameParagraph = document.createElement("p");
                nameParagraph.setAttribute("class", "lead");

                const nameLabel = document.createElement("label");
                nameLabel.setAttribute("class", "video-grid-label");
                nameLabel.innerText = name;

                const breakElement = document.createElement("br");
                nameParagraph.appendChild(nameLabel);
                nameParagraph.appendChild(breakElement);

                if (description && description.length > 0) {
                    const descLabel = document.createElement("label");
                    descLabel.setAttribute("class", "video-grid-label");

                    descLabel.innerText = description.split(";")[0];
                    nameParagraph.appendChild(descLabel);
                }

                const wrapper = document.createElement("div");
                wrapper.appendChild(videoLink);
                wrapper.appendChild(nameParagraph);
                grid.append(wrapper);
            }
        }
    }
})();
