require("../js/video");
require("tus-js-client");
require("bootstrap/js/dist/scrollspy");
require("bootstrap/js/dist/collapse");
require("bootstrap/js/dist/carousel");
require("bootstrap/dist/css/bootstrap.css");

//require("../css/bootstrap.min.css");
require("../css/styles.css");
require("../css/video.css");
require("../css/fonts.css");
